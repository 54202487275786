<template>
  <div class="p-wrap">
    <div class="p-header">
      <div class="p-tip">
        <div class="tip-label">{{ label }}</div>
        <div class="tip-area">
          <div class="radio" @click="handleRadioSwitch">
            <input type="radio" v-model="isShowSelect" :value="true"/><span>显示单元格信息</span>
          </div>
          <el-button type="default" @click="handleView">{{ screenButtonMsg }}</el-button>
        </div>
      </div>
      <div class="p-select" v-if="isShowSelect">
        <div class="row">
          <i>位置： <span>{{ cellPosition }}</span></i>
          <div>
            <label>取数规则：</label>
            <span>{{ cellRule }}</span>
          </div>
        </div>
        <div class="row">
          <i>名称： <span>{{ cellName }}</span></i>
          <div class="items">
            <div>
              <label>取数调整：</label>
              <span>调整前</span>
              <el-input type="text" v-model="cellBefore" show-word-limit disabled/>
            </div>
            <div>
              <span>调整后</span>
              <el-input type="text" v-model.trim="cellAfter" disabled/>
            </div>
            <div>
              <span>调整原因</span>
              <el-input type="text" v-model.trim="reason" disabled/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-main">
      <div class="main-title">
        <div class="history" @click="$emit('toHistory')">底稿调整记录</div>
        <div>
          <el-button type="default" @click="handleExport">导出Excel</el-button>
          <el-button type="default" @click="handleEdit" :disabled="btnDisabled">修改底稿</el-button>

          <!--            已申报未扣款 按钮可点击-->
          <el-button type="primary" @click="handleConfirmHkShow"
                     :disabled="declare.declareStatus != '512'">
            划款
          </el-button>
          <el-button v-role="'DEC-TMPL-ENT-QUERY-F08'" type="primary" @click="handleConfirmShow"
                     :disabled="btnSBDisabled">
            点击申报
          </el-button>
          <!--          <el-button v-role="'DEC-TMPL-ENT-QUERY-F02'" type="default"-->
          <!--                     :disabled="!(declare.approvalStatus=='UNCOMMITTED' || declare.approvalStatus=='REVIEWFAILED'  || declare.approvalStatus=='APPROVALFAILED')"-->
          <!--                     @click="handleSubmitApprovalTmpl">提交审批-->
          <!--          </el-button>-->
          <el-button v-role="'DEC-TMPL-ENT-QUERY-F02'" type="default"
                     :disabled="!(declare.approvalStatus=='UNCOMMITTED' || declare.approvalStatus=='REVIEWFAILED'  || declare.approvalStatus=='APPROVALFAILED')"
                     @click="handleSubmitRemarkAuditorTmpl('UNREVIEWED')">提交审批
          </el-button>
          <!--          <el-button v-role="'DEC-TMPL-ENT-QUERY-F03'" type="default"-->
          <!--                     v-show="btnReviewReturnVisible(declare)"-->
          <!--                     :disabled="!(declare.approvalStatus=='UNREVIEWED') || declare.isHistory"-->
          <!--                     @click="handleSubmitReturnAuditorTmpl('REVIEWFAILED')">退回-->
          <!--          </el-button>-->
          <el-button v-role="'DEC-TMPL-ENT-QUERY-F01'" type="default"
                     v-show="btnReviewVisible(declare)"
                     :loading="btnLoading"
                     :disabled="!(declare.approvalStatus=='UNREVIEWED' ) || declare.isHistory"
                     @click="handleSubmitReturnAuditorTmpl('APPROVALPENDING')">审批
          </el-button>
          <!--          <el-button v-role="'DEC-TMPL-ENT-QUERY-F04'" type="default"-->
          <!--                     v-show="btnApprovalReturnVisible(declare)"-->
          <!--                     :disabled="!(declare.approvalStatus=='APPROVALPENDING') || declare.isHistory"-->
          <!--                     @click="handleSubmitReturnAuditorTmpl('APPROVALFAILED')">退回-->
          <!--          </el-button>-->
          <el-button v-role="'DEC-TMPL-ENT-QUERY-F05'" type="default"
                     v-show="btnApprovalVisible(declare)"
                     :loading="btnLoading"
                     :disabled="!(declare.approvalStatus=='APPROVALPENDING') || declare.isHistory"
                     @click="handleSubmitReturnAuditorTmpl('APPROVED')">审批
          </el-button>
          <el-button type="default"
                     v-show="declare.declareStatus=='610'"
                     @click="downloadPdf(scope.row)"
                     :disabled="declare.isHistory">盖章文件
          </el-button>
        </div>
      </div>
      <div class="xlsx">
        <div id="template"></div>
      </div>
    </div>
    <el-dialog append-to-body :visible.sync="confirmVisible"
               title="是否提交申报"
               :show-close="false"
               @close="confirmVisible = false"
               width="428px"
    >
      <div class="dialog-wrap">
        <!--        <el-form class="form" ref="confirmForm" :model="confrimForm" :rules="confirmRules" label-position="top">-->
        <!--          <el-form-item prop="jbrzfzh" label="申报人身份证号" required>-->
        <!--            <el-input v-model="confrimForm.jbrzfzh" placeholder="请输入身份证号" max="18" style="width:380px"></el-input>-->
        <!--          </el-form-item>-->
        <!--        </el-form>-->
        <div class="content">
          <span class="tip">{{ declare.declarePeriod | fmtDeclarePeriod }}</span>
          <span>征期需申报的增值税税额为：</span>
          <span class="tip">{{ taxAmount }}</span>
        </div>
        <div class="dialog-footer">
          <el-button type="default" @click="confirmVisible = false">取消</el-button>
          <el-button type="primary" @click="handleDeclare"
                     :loading="declareLoading"
                     :disabled="btnDisabled">申报
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog append-to-body :visible.sync="confirmHkVisible"
               title="是否执行划款"
               :show-close="false"
               @close="confirmHkVisible = false"
               width="428px"
    >
      <div class="dialog-wrap">
        <div class="content">
          <div class="contentDiv" style="background: #EBF4FF;font-weight: bold;padding:10px 0"><span>所属税期</span>
            <span class="tip">{{ declare.declarePeriod | fmtDeclarePeriod }}</span>
            <span>应缴纳税额</span>
            <span class="tip">{{ taxAmount }}</span></div>
          <!--                  <div class="contentDiv" style="">  <span>增值税</span>-->
          <!--                      <span class="tip">{{ zzs }}</span></div>-->
          <el-row class="contentDiv">
            <el-col :span="12" style="border-right: 1px solid #EEEEEE; padding:10px 0">
              <div style="text-align: center"><span>增值税</span></div>
            </el-col>
            <el-col :span="12">
              <div class="cDiv">
                <span class="tip">{{ zzs }}</span></div>
            </el-col>
          </el-row>
          <el-row class="contentDiv" style="background: #FAFAFA;">
            <el-col :span="12" style="border-right: 1px solid #EEEEEE; padding:10px 0">
              <div style="text-align: center"><span>城市维护建设税</span></div>
            </el-col>
            <el-col :span="12">
              <div class="cDiv">
                <span class="tip">{{ cswfjss }}</span></div>
            </el-col>
          </el-row>
          <el-row class="contentDiv" style="">
            <el-col :span="12" style="border-right: 1px solid #EEEEEE; padding:10px 0">
              <div style="text-align: center"><span>教育费附加</span></div>
            </el-col>
            <el-col :span="12">
              <div class="cDiv">
                <span class="tip">{{ jyffj }}</span></div>
            </el-col>
          </el-row>

          <el-row class="contentDiv" style="background: #FAFAFA;">
            <el-col :span="12" style="border-right: 1px solid #EEEEEE; padding:10px 0">
              <div style="text-align: center"><span>地方教育附加</span></div>
            </el-col>
            <el-col :span="12">
              <div class="cDiv">
                <span class="tip">{{ dfjyfj }}</span></div>
            </el-col>
          </el-row>

          <el-row class="contentDiv" style="background: #EBF4FF;">
            <el-col :span="12" style="border-right: 1px solid #EEEEEE; padding:10px 0">
              <div style="text-align: center"><span>合计</span></div>
            </el-col>
            <el-col :span="12">
              <div class="cDiv">
                <span class="tip">{{ hj }}</span></div>
            </el-col>
          </el-row>
        </div>
        <div class="dialog-footer">
          <el-button type="default" @click="confirmHkVisible = false">取消</el-button>
          <el-button type="primary" @click="handleQrhk"
                     :loading="declareHkLoading"
                     :disabled="btnHkDisabled">确认划款
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 编辑发票说明弹窗 -->
    <el-dialog append-to-body :visible.sync="editVisibleExplain" width="34%" label-position="top" :show-close="false">
      <div class="edit-wrap">
        <div class="edit-title">审批意见</div>
        <el-form class="edit-form" ref="approvalLogForm" :model="approvalLogForm">
          <el-input type="textarea" :rows="5" maxlength="200" show-word-limit
                    placeholder="请输入审批意见" v-model="approvalLogForm.returnReason">
          </el-input>
        </el-form>
        <div slot="footer" class="edit-footer">
          <el-row :gutter="20">
            <el-col :span="8">
              <div style="text-align: left">
                <el-button @click="updateAuditorOpinion">暂 存</el-button>
              </div>
            </el-col>
            <el-col :span="16">
              <div style="text-align: right">
                <el-button type="primary" @click="updateReturnAuditor('th')">退 回</el-button>
                <el-button type="primary" @click="updateReturnAuditor('sp')">同 意</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
    <!-- 编辑备注弹窗 -->
    <el-dialog append-to-body :visible.sync="editVisibleRemark" width="34%" label-position="top" :show-close="false">
      <div class="edit-wrap">
        <div class="edit-titleRemark">备注</div>
        <el-form class="edit-form" ref="remarkForm" :model="remarkForm">
          <el-input type="textarea" :rows="5" maxlength="200" show-word-limit
                    placeholder="请输入备注信息" v-model="remarkForm.returnReason">
          </el-input>
        </el-form>
        <div slot="footer" class="edit-footer">
          <el-button @click="hiddenRemark">取 消</el-button>
          <el-button type="primary" @click="updateRemarkAuditor">保 存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getLog,
  getDeclareByDraftTableId,
  declareSubmit,
  declareHkSubmit,
  submitApprovalDeclareTmpl,
  saveApprovalOpinion,
  downloadPdf,
  resetDeclareDetail
} from '@/service/declare/index'
import { baseURL } from '@/service/index'
import { constantsWs } from '@/service/ws-constants'
import { exportExcel } from '../../data/export'

export default {
  name: 'Manuscript',
  computed: {
    screenButtonMsg () {
      return this.isFullScreen ? '退出' : '全屏'
    },
    btnDisabled () {
      return this.declare.declareStatus != '301'//生成申报表
    },
    btnHkDisabled () {
      return this.declare.declareStatus != '512'//已申报未扣款
    },
    btnSBDisabled () {
      var b = !(this.declare.declareMode == 'ALONE' && this.declare.declareStatus == '301' && this.declare.approvalStatus == 'APPROVED')
      console.log('b:', b)
      return b//申报
    }
  },
  watch: {
    confirmVisible () {
      if (!this.confirmVisible) this.confrimForm.jbrzfzh = ''
    },
    declareProp () {
      this.initXlsx()
    }
  },
  filters: {
    // 格式化征期
    fmtDeclarePeriod (val) {
      if (val) {
        let arr = val.split('')
        arr.splice(6, 0, '月')
        arr.splice(4, 0, '年')
        return arr.join('')
      } else {
        return '--'
      }
    }
  },
  props: ['declareProp'],
  data () {
    return {
      // 申报信息
      declare: {},
        btnLoading:false,
      id: btoa(this.$route.query.id),
      confirmVisible: false,
      confirmHkVisible: false,
      declareLoading: false,
      declareHkLoading: false,
      confrimForm: {
        jbrzfzh: '' // 身份证号
      },
      taxAmount: '', // 增值税税款
      sq: '', // 税期
      zzs: '', // 增值税
      cswfjss: '', // 城市维护建设
      jyffj: '', // 教育附加税
      dfjyfj: '', // 地方教育附加
      hj: '', // 合计
      isFullScreen: false,
      isShowSelect: true,
      label: '',
      cellPosition: '',    // 单元格位置
      cellName: '',        // 单元格名称
      cellRule: '',        // 取数规则
      cellBefore: '',
      cellAfter: '',
      reason: '',
      taxCategoryList: [],
      declareModeList: [],
      approvalLog: {
        approvalOpinion: '',//拒绝原因
        id: undefined,//申报模板ID
        approvalStatus: undefined//审批状态
      },
      /* 复核退回弹窗 */
      editVisibleExplain: false,
      editVisibleRemark: false,
      approvalLogForm: {
        returnReason: ''
      },
      remarkForm: {
        returnReason: ''
      }
    }
  },
  created () {
    document.addEventListener('webkitfullscreenchange', () => {
      if (!document.webkitIsFullScreen && this.isFullScreen == true) this.handleView()
    })
    this.initManuscript()
  },
  methods: {
    // 初始化底稿
    async initManuscript () {
      await this.initDict()
    },
    // 初始化字典
    async initDict () {
      this.getDicts('dec_tax_category').then(res => {
        if (res.success) this.taxCategoryList = res.data
      })
      this.getDicts('dec_declare_mode').then(res => {
        if (res.success) this.declareModeList = res.data
      })
    },
    // 初始化xlsx
    async initXlsx () {
      console.log('had init')
      let data = this.declareProp
      this.declare = data

      if (!data.tableId) this.toast('当前申报信息未生成申报表', 'warning')
      let taxPerson = data.taxPerson == '1' ? '一般纳税人' : '小规模纳税人'
      let taxCategory = this.getDictLabel(this.taxCategoryList, data.taxCategory)
      let declareMode = this.getDictLabel(this.declareModeList, data.delcareMode)
      this.label = `${data.declarePeriod} ${data.orgName} ${taxCategory} ${taxPerson} ${declareMode}(底稿)`

        let options = {
          container: 'template',
          loadUrl: `${baseURL}/declare/data/table/${data.tableId}`,
          // 表格控制配置
          enableAddRow: false,
          enableAddCol: false,
          userInfo: false,
          showsheetbarConfig: {
            add: false, //新增sheet
            menu: false, //sheet管理菜单
          },
          showinfobar: false,
          showtoolbar: false,
          allowCopy: false,
          lang: 'zh',
          hook: {
            cellMousedown: (cell, position) => this.hadnleCellMouseDown(cell, position)
          }
        }
        luckysheet.create(options)
      },
    // 鼠标点击
    async hadnleCellMouseDown (cell, position) {
      // 补充取数规则
      this.cellRule = cell.ps ? cell.ps.value : ''

      const { success, data } = await getLog({ declareTmplId: this.declare.draftTableId, ...position })
      if (success && data != null) {
        this.cellBefore = data.historyValue
        this.cellAfter = data.currentValue
        this.reason = data.reason
        if (data.c > 25) {
          let symbol = Math.floor(data.c / 25) - 1
          let suffix = data.c % 25
          this.cellName = this.cellPosition = `${String.fromCharCode(symbol + 65)}${String.fromCharCode(suffix + 65)}${data.r + 1}`
        } else {
          this.cellName = this.cellPosition = `${String.fromCharCode(data.c + 65)}${data.r + 1}`
        }
      } else {
        this.cellBefore = ''
        this.cellAfter = ''
        this.reason = ''
        // 坐标转换 3，5 -> C5
        if (position.c > 25) {
          let symbol = Math.floor(position.c / 25) - 1
          let suffix = position.c % 25
          this.cellName = this.cellPosition = `${String.fromCharCode(symbol + 65)}${String.fromCharCode(suffix + 65)}${position.r + 1}`
        } else {
          this.cellName = this.cellPosition = `${String.fromCharCode(position.c + 65)}${position.r + 1}`
        }
      }
    },
    // 显示单元格点选
    handleRadioSwitch () {
      this.isShowSelect = !this.isShowSelect
    },
    // Excel导出
    handleExport () {
      exportExcel(luckysheet.getAllSheets(), '下载')
    },
    // 重新生成底稿
    handleEdit () {
      this.$emit('change', 'manuscript')
    },
    // 纳税申报弹窗展示
    handleConfirmShow () {
      this.confirmVisible = true
      let taxAmount = luckysheet.getCellValue(33, 18, { order: 0 })
      this.taxAmount = (taxAmount ? taxAmount : 0) + '元'
    },
    // 纳税划款弹窗展示
    handleConfirmHkShow () {
      this.confirmHkVisible = true
      let zzs = luckysheet.getCellValue(33, 18, { order: 0 })
      this.zzs = this.numFormat(zzs ? zzs : 0)
      let cswfjss = luckysheet.getCellValue(48, 18, { order: 0 })
      this.cswfjss = this.numFormat(cswfjss ? cswfjss : 0)
      let jyffj = luckysheet.getCellValue(49, 18, { order: 0 })
      this.jyffj = this.numFormat(jyffj ? jyffj : 0)
      let dfjyfj = luckysheet.getCellValue(50, 18, { order: 0 })
      this.dfjyfj = this.numFormat(dfjyfj ? dfjyfj : 0)
      let hj = zzs + cswfjss + jyffj + dfjyfj
      this.hj = this.numFormat(hj ? hj : 0)
    },
    numFormat (num) {
      var c = (num.toString().indexOf('.') !== -1) ? num.toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      return c
    },
    // 申报纳税
    handleDeclare () {
      this.declareLoading = true
      declareSubmit(this.declare.id).then(({ success }) => {
        this.declareLoading = false
        if (success) {
          this.toast('纳税申报提交成功', 'success')
          this.$router.replace('/declare/ent/tmpl/list')
        }
      })
    },
    // 确认划款
    handleQrhk () {
      this.declareHkLoading = true
      declareHkSubmit(this.declare.id).then(({ success }) => {
        this.declareHkLoading = false
        if (success) {
          this.toast('划款提交成功', 'success')
          this.$router.replace('/declare/ent/tmpl/list')
        }
      })
    },
    // 全屏现实
    handleView () {
      this.isFullScreen = !this.isFullScreen
      this.isFullScreen ? document.body.webkitRequestFullscreen() : document.webkitExitFullscreen()
      this.$bus.$emit('full-screen', this.isFullScreen)
    },
    //待提交UNCOMMITTED	待复核UNREVIEWED 	退回（复核未通过）REVIEWFAILED 	待审批APPROVALPENDING
    // 退回（审批未通过）APPROVALFAILED	审批通过APPROVED
    // 复核员 审批员 退回
    // handleSubmitReturnAuditorTmpl (status) {
    //   //1.角色，2.审批状态
    //   this.editVisibleExplain = true
    //   this.approvalLog.id = this.declare.id
    //   this.approvalLog.approvalStatus = status
    //   console.log('status:', status)
    //   console.log('this.approvalLog.approvalStatus:', this.approvalLog.approvalStatus)
    // },
    // 复核员 审批员 退回
    handleSubmitReturnAuditorTmpl (status) {
      //1.角色，2.审批状态
      this.editVisibleExplain = true
      this.approvalLog.id = this.declare.id
      this.approvalLogForm.returnReason = this.declare.approvalOpinion
      this.approvalLog.approvalStatus = status
      // console.log('status:', status)
      // console.log('this.approvalLog.approvalStatus:', this.approvalLog.approvalStatus)
    },
    /* 收起 保存审批意见*/
    updateAuditorOpinion () {
      this.approvalLog.approvalOpinion = this.approvalLogForm.returnReason
        this.btnLoading = true
      saveApprovalOpinion(this.approvalLog).then(res => {
          this.btnLoading = false
        this.toast('当前编辑内容已保存', 'success', () => this.initXlsx())
        this.hiddenReturnAuditor()
          this.$emit('change', 'declare')
      })
    },
    /* 复核员审核退回确认 */
    updateReturnAuditor (status) {
      this.approvalLog.approvalOpinion = this.approvalLogForm.returnReason

      if (status == 'th') {//退回 审批原因不能为空
        if (!this.approvalLog.approvalOpinion) {
          this.toast('审批原因不可为空', 'error')
          return
        }
        if (this.approvalLog.approvalStatus == 'APPROVALPENDING') {
          this.approvalLog.approvalStatus = 'REVIEWFAILED'
        }
        if (this.approvalLog.approvalStatus == 'APPROVED') {
          this.approvalLog.approvalStatus = 'APPROVALFAILED'
        }
      }
      submitApprovalDeclareTmpl(this.approvalLog).then(res => {
        // console.log(res)
        this.returnReason = ''
        this.approvalLog.approvalOpinion = ''
        this.toast(res.message, 'success', () => this.initXlsx())
        this.hiddenReturnAuditor()
          this.$emit('change', 'declare')
      })
    },

    // 操作员弹窗
    handleSubmitRemarkAuditorTmpl (status) {
      //1.角色，2.审批状态
      this.editVisibleRemark = true
      this.approvalLog.id = this.declare.id
      this.approvalLog.approvalStatus = status
      console.log('status:', status)
      console.log('this.approvalLog.approvalStatus:', this.approvalLog.approvalStatus)
    },
    /* 操作员审批 */
    updateRemarkAuditor () {
      this.approvalLog.approvalOpinion = this.remarkForm.returnReason

      submitApprovalDeclareTmpl(this.approvalLog).then(res => {
        // console.log(res)
        this.returnReason = ''
        this.approvalLog.approvalOpinion = ''
        this.toast(res.message, 'success', () => this.initXlsx())
        this.hiddenRemark()
          this.$emit('change', 'declare')
      })
    },
    /* 隐藏 */
    hiddenReturnAuditor () {
      this.approvalLogForm.returnReason = ''
      this.editVisibleExplain = false
    },
    /* 隐藏备注 */
    hiddenRemark () {
      this.remarkForm.returnReason = ''
      this.editVisibleRemark = false
    },
    //复核员 审批员 提交审批
    handleSubmitAuditorTmpl () {
      if (this.declare.approvalStatus == 'UNREVIEWED') {//待复核 并且 复核员
        this.approvalLog.approvalStatus = 'APPROVALPENDING'
      }
      if (this.declare.approvalStatus == 'APPROVALPENDING') {//审批员 并且 待审批
        this.approvalLog.approvalStatus = 'APPROVED'
      }
      this.approvalLog.id = this.declare.id
      submitApprovalDeclareTmpl(this.approvalLog).then(res => {
        console.log(res)
        this.toast(res.message, 'success', () => this.initXlsx())
        this.hiddenReturnAuditor()
          this.$emit('change', 'declare')
      })
    },
    btnReviewVisible (row) {
      let arr = this.$store.state.permission.roles
      if (arr.indexOf('DEC-TMPL-ENT-QUERY-F05') > -1) {
        const status = row.approvalStatus
        let visible = false
        switch (status) {
          case 'UNCOMMITTED':
          case 'UNREVIEWED':
          case 'REVIEWFAILED':
          case 'APPROVALFAILED':
          case 'APPROVED':
            visible = true
            break
          case 'APPROVALPENDING':
          default:
            visible = false
            break
        }
        console.log('ReviewButton stauts:', status, ' visible:', visible)
        return visible
      } else {
        return true
      }
    },
    btnApprovalVisible (row) {
      const status = row.approvalStatus
      let arr = this.$store.state.permission.roles
      if (arr.indexOf('DEC-TMPL-ENT-QUERY-F01') > -1) {
        let visible = false
        switch (status) {
          case 'UNCOMMITTED':
          case 'UNREVIEWED':
          case 'REVIEWFAILED':
          case 'APPROVALFAILED':
          case 'APPROVED':
            visible = false
            break
          case 'APPROVALPENDING':
          default:
            visible = true
            break
        }
        // if (!this.btnReviewVisible(row)) {
        //     visible = true;
        // }
        console.log('ApprovalButton stauts:', status, ' visible:', visible)
        return visible
      } else {
        return true
      }

    },
    btnReviewReturnVisible (row) {
      let arr = this.$store.state.permission.roles
      if (arr.indexOf('DEC-TMPL-ENT-QUERY-F05') > -1) {
        const status = row.approvalStatus
        let visible = false
        switch (status) {
          case 'UNCOMMITTED':
          case 'UNREVIEWED':
          case 'REVIEWFAILED':
          case 'APPROVALFAILED':
          case 'APPROVED':
            visible = true
            break
          case 'APPROVALPENDING':
          default:
            visible = false
            break
        }
        console.log('ReviewButton stauts:', status, ' visible:', visible)
        return visible
      } else {
        return true
      }
    },
    btnApprovalReturnVisible (row) {
      const status = row.approvalStatus
      let arr = this.$store.state.permission.roles
      if (arr.indexOf('DEC-TMPL-ENT-QUERY-F01') > -1) {
        let visible = false
        switch (status) {
          case 'UNCOMMITTED':
          case 'UNREVIEWED':
          case 'REVIEWFAILED':
          case 'APPROVALFAILED':
          case 'APPROVED':
            visible = false
            break
          case 'APPROVALPENDING':
          default:
            visible = true
            break
        }
        console.log('ApprovalButton stauts:', status, ' visible:', visible)
        return visible
      } else {
        return true
      }
    },
    //下载影像
    async downloadPdf (row) {
      const image = await downloadPdf(row)
      console.log('image:' + image.data)
      if (!image) {
        this.toast('下载服务发生错误！', 'error')
        return
      }
      let url = window.URL.createObjectURL(new Blob([image.data], {
        type: 'application/x-msdownload'
      }))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '完税凭证文件.pdf')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    btnSBVisible (row) {
      let arr = this.$store.state.permission.roles
      if (arr.indexOf('DEC-TMPL-ENT-QUERY-F02') > -1) {
        console.log('declareMode:', row.declareMode)
        console.log('declareStatus:', row.declareStatus)
        console.log('approvalStatus:', row.approvalStatus)
        if ((row.declareMode != 'ALONE' && row.declareStatus != '301') || row.approvalStatus != 'APPROVED') {
          return false
        }
      }
      return true
    }
  }
}

</script>

<style scoped lang="scss">
.p-wrap {
  display: flex;
  flex-direction: column;
}

.p-header {
  width: auto;
  padding: 24px 24px 0;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);

  .p-tip {
    display: flex;
    padding: 14px 16px;
    margin-bottom: 14px;
    flex-direction: row;
    align-items: center;
    background-color: #EBF4FF;
    justify-content: space-between;

    .tip-label {
      color: #333333;
      font-size: 16px;
    }

    .tip-area {
      .radio {
        display: inline-block;
        margin-right: 20px;

        span {
          color: #666666;
          margin-left: 8px;
          vertical-align: top;
        }
      }

      .radio:hover {
        cursor: pointer;
      }
    }
  }

  .p-select {
    display: flex;
    color: #666666;
    padding: 0 16px 14px;
    flex-direction: column;
    justify-content: center;

    .row {
      padding: 10px;
      display: flex;
      flex-direction: row;

      i {
        width: 160px;
        font-style: normal;
        display: inline-block;
      }
    }

    .label {
      min-width: 160px;
    }

    .items {
      flex: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      span {
        margin-right: 8px;
      }
    }
  }

}

.p-main {
  display: flex;
  padding: 12px 24px;
  margin-top: 24px;
  flex-direction: column;
  background-color: #fff;

  .main-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .history {
      color: #1890FF;
      font-size: 16px;
      font-weight: 500;
    }

    .history:hover {
      cursor: pointer;
    }
  }

  .xlsx {
    margin-top: 12px;

    #template {
      display: block;
      height: 100%;
      min-height: 650px;
      width: 100%;
    }
  }
}

.dialog-wrap {
  padding: 0;
  margin-top: 0;
  text-align: left;

  .form {
    padding: 0 24px 32px;
  }

  .content {
    padding: 24px 24px 32px;
    font-size: 14px;
    color: #333333;

    .tip {
      color: #666666;
    }

    .contentDiv {
      text-align: center;
      padding: 0;
      font-size: 14px;

    }

    .cDiv {
      text-align: center;
      padding: 10px 0;
    }
  }

  .dialog-footer {
    padding: 0 24px 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.edit-wrap {
  padding: 0;
  margin-top: -30px;

  .edit-title {
    text-align: left;
    padding: 10px 24px 30px;
    color: #333333;
  }

  .edit-titleRemark {
    text-align: left;
    padding: 0 24px 30px;
    color: #333333;
  }

  .edit-title:after {
    content: "*";
    color: #F5222D;
  }

  .edit-form {
    padding: 0 24px;
  }

  .edit-footer {
    padding: 24px;
    text-align: right;
  }
}

::v-deep .el-dialog .el-dialog__body {
  padding: 0 0 0 0;
}
</style>

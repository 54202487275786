<template>
  <div class="p-wrap">
    <div class="p-header">
      <div class="p-tip">
        <div class="tip-label">{{ label }}</div>
        <div class="tip-area">
          <div class="radio" @click="handleRadioSwitch">
            <input type="radio" v-model="isShowSelect" :value="true"/><span>显示单元格信息</span>
          </div>
          <el-button type="default" @click="handleView">{{ screenButtonMsg }}</el-button>
        </div>
      </div>
      <div class="p-select" v-if="isShowSelect">
        <div class="row">
          <i>位置： <span>{{ cellPosition }}</span></i>
          <div>
            <label>取数规则：</label>
            <span>{{ cellRule }}</span>
          </div>
        </div>
        <div class="row">
          <i>名称： <span>{{ cellName }}</span></i>
          <div class="items">
            <div>
              <label>取数调整：</label>
              <span>调整前</span>
              <el-input type="text" v-model="cellBefore" show-word-limit disabled/>
            </div>
            <div>
              <span>调整后</span>
              <el-input type="text" v-model.trim="cellAfter" disabled/>
            </div>
            <div>
              <span>调整原因</span>
              <el-input type="text" v-model.trim="reason" disabled/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-main">
      <div class="main-title">
        <div class="history" @click="$emit('toHistory')">底稿调整记录</div>
        <div>
          <el-button type="default" @click="handleExport">导出Excel</el-button>
          <el-button v-if="false" type="default" @click="handleReset">重新生成底稿</el-button>
          <el-button type="primary" @click="handleSave" :disabled="btnDisabled">保存修改</el-button>
          <!--            <el-button type="primary" @click="handleSave" >保存修改</el-button>-->
          <!--            <el-button type="primary" v-role="'DEC-TMPL-ENT-QUERY-F09'"  @click="handleBuildDeclare" :loading="btnLoading" >生成申报表-->
          <!--            </el-button>-->
          <el-button type="primary" v-role="'DEC-TMPL-ENT-QUERY-F09'" @click="handleBuildDeclare" :loading="btnLoading"
                     :disabled="btnDisabled">生成申报表
          </el-button>
        </div>
      </div>
      <div class="xlsx">
        <div id="template"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLog,
  getDeclareByDraftTableId,
  buildDeclare,
  resetDeclareDetail,
  saveSheets,
  saveLogs
} from '@/service/declare/index'
import { baseURL } from '@/service/index'
import { constantsWs } from '@/service/ws-constants'
import { exportExcel } from '../../data/export'

export default {
  name: 'Manuscript',
  computed: {
    screenButtonMsg () {
      return this.isFullScreen ? '退出' : '全屏'
    },
    // 保存按钮/生成申报表按钮     提交失败（302），编辑底稿 可用
    btnDisabled () {
      var b = !(this.declare.declareStatus == '302' || this.declare.declareStatus == '300')
      console.log('btnDisabled', b)
      console.log('declareStatus', this.declare.declareStatus)
      return b
    }
  },
  props: ['declareProp'],
  data () {
    return {
      // 申报信息
      declare: {},
      id: btoa(this.$route.query.id),
      isFullScreen: false,
      isShowSelect: true,
      btnLoading: false,
      label: '',
      cellPosition: '',    // 单元格位置
      cellName: '',        // 单元格名称
      cellRule: '',        // 取数规则
      cellBefore: '',
      cellAfter: '',
      reason: '',
      taxCategoryList: [],
      declareModeList: [],
      logs: []
    }
  },
  created () {
    document.addEventListener('webkitfullscreenchange', () => {
      if (!document.webkitIsFullScreen && this.isFullScreen == true) this.handleView()
    })
    this.initManuscript()
  },
  watch: {
    declareProp () {
      this.initXlsx()
    }
  },
  methods: {
    // 初始化底稿
    async initManuscript () {
      await this.initDict()
    },
    // 初始化字典
    async initDict () {
      this.getDicts('dec_tax_category').then(res => {
        if (res.success) this.taxCategoryList = res.data
      })
      this.getDicts('dec_declare_mode').then(res => {
        if (res.success) this.declareModeList = res.data
      })
    },
    // 保存excel
    async handleSave () {
      let sheets = luckysheet.getAllSheets()

      const { success } = await saveSheets({
        id: this.id,
        sheets: sheets.map(i => {
          return {
            sheet: i
          }
        })
      })
      const logRes = await saveLogs(this.logs)
      // 暂未做失败重试或回滚
      if (success) this.toast('保存底稿成功', 'success')
    },
    // 初始化xlsx
    initXlsx () {

      let data = this.declareProp
      this.declare = data
      let taxPerson = data.taxPerson == '1' ? '一般纳税人' : '小规模纳税人'
      let taxCategory = this.getDictLabel(this.taxCategoryList, data.taxCategory)
      let declareMode = this.getDictLabel(this.declareModeList, data.delcareMode)
      this.label = `${data.declarePeriod} ${data.orgName} ${taxCategory} ${taxPerson} ${declareMode}(底稿)`

      let options = {
        container: 'template',
        loadUrl: `${baseURL}/declare/data/${this.id}`,
        // 表格控制配置
        enableAddRow: false,
        enableAddCol: false,
        userInfo: false,
        showsheetbarConfig: {
          add: false, //新增sheet
          menu: false, //sheet管理菜单
        },
        showtoolbarConfig: {
          function: true,
          postil: true,
          findAndReplace: true
        },
        forceCalculation: true, // 强制计算公式
        showinfobar: false,
        showtoolbar: false,
        allowCopy: false,
        lang: 'zh',
        hook: {
          cellUpdated: (r, c, o, n) => this.handleSetValue(r, c, o, n),
          cellMousedown: (cell, position) => this.hadnleCellMouseDown(cell, position)
        }
      }
      luckysheet.create(options)
    },
    // 设置单元格信息操作
    handleSetValue (r, c, o, n) {
      if (!n.v) return
      let log = {
        declareTmplId: this.id,
        r: r,
        c: c,
        sheetName: luckysheet.getSheet().name,
        historyValue: o.v,
        currentValue: n.v
      }
      const index = this.logs.findIndex(i => i.r == log.r && i.c == log.c)
      index > -1 ? this.logs.splice(index, 1, log) : this.logs.push(log)
    },
    // 鼠标点击
    async hadnleCellMouseDown (cell, position) {
      // 补充取数规则
      this.cellRule = cell.ps ? cell.ps.value : ''
      console.log('cell', this.cellRule)
      const { success, data } = await getLog({ declareTmplId: this.id, ...position })
      if (success && data != null) {
        this.cellBefore = data.historyValue
        this.cellAfter = data.currentValue
        this.reason = data.reason

        if (data.c > 25) {
          let symbol = Math.floor(data.c / 25) - 1
          let suffix = data.c % 25
          this.cellName = this.cellPosition = `${String.fromCharCode(symbol + 65)}${String.fromCharCode(suffix + 65)}${data.r + 1}`
        } else {
          this.cellName = this.cellPosition = `${String.fromCharCode(data.c + 65)}${data.r + 1}`
        }
      } else {
        this.cellBefore = ''
        this.cellAfter = ''
        this.reason = ''
        // 坐标转换 3，5 -> C5
        if (position.c > 25) {
          let symbol = Math.floor(position.c / 25) - 1
          let suffix = position.c % 25
          this.cellName = this.cellPosition = `${String.fromCharCode(symbol + 65)}${String.fromCharCode(suffix + 65)}${position.r + 1}`
        } else {
          this.cellName = this.cellPosition = `${String.fromCharCode(position.c + 65)}${position.r + 1}`
        }
      }
    },
    // 显示单元格点选
    handleRadioSwitch () {
      this.isShowSelect = !this.isShowSelect
    },
    // Excel导出
    handleExport () {
      exportExcel(luckysheet.getAllSheets(), '下载')
    },
    // 重新生成底稿
    async handleReset () {
      const { success } = await resetDeclareDetail(this.declare)
      if (success) this.toast('重新生成底稿成功', 'success', () => window.onload())
    },
    // 生成申报表
    async handleBuildDeclare () {
      this.btnLoading = true
      const { success } = await buildDeclare(this.declare)
      this.btnLoading = false
      if (success) {
          this.toast('申报表生成中，请稍等！', 'warning')
          // this.toast('生成申报表成功', 'success')
        // this.$emit('change', 'declare')
      }
    },
    // 全屏现实
    handleView () {
      this.isFullScreen = !this.isFullScreen
      this.isFullScreen ? document.body.webkitRequestFullscreen() : document.webkitExitFullscreen()
      this.$bus.$emit('full-screen', this.isFullScreen)
    }
  }
}
</script>

<style scoped lang="scss">
.p-wrap {
  display: flex;
  flex-direction: column;
}

.p-header {
  width: auto;
  padding: 24px 24px 0;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);

  .p-tip {
    display: flex;
    padding: 14px 16px;
    margin-bottom: 14px;
    flex-direction: row;
    align-items: center;
    background-color: #EBF4FF;
    justify-content: space-between;

    .tip-label {
      color: #333333;
      font-size: 16px;
    }

    .tip-area {
      .radio {
        display: inline-block;
        margin-right: 20px;

        span {
          color: #666666;
          margin-left: 8px;
          vertical-align: top;
        }
      }

      .radio:hover {
        cursor: pointer;
      }
    }
  }

  .p-select {
    display: flex;
    color: #666666;
    padding: 0 16px 14px;
    flex-direction: column;
    justify-content: center;

    .row {
      padding: 10px;
      display: flex;
      flex-direction: row;

      i {
        width: 160px;
        font-style: normal;
        display: inline-block;
      }
    }

    .label {
      min-width: 160px;
    }

    .items {
      flex: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      span {
        margin-right: 8px;
      }
    }
  }

}

.p-main {
  display: flex;
  padding: 12px 24px;
  margin-top: 24px;
  flex-direction: column;
  background-color: #fff;

  .main-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .history {
      color: #1890FF;
      font-size: 16px;
      font-weight: 500;
    }

    .history:hover {
      cursor: pointer;
    }
  }

  .xlsx {
    margin-top: 12px;

    #template {
      display: block;
      height: 100%;
      min-height: 650px;
      width: 100%;
    }
  }
}
</style>
